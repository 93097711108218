<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      COPYRIGHT © {{ new Date().getFullYear() }}
      <b-link class="ml-25" href="https://www.intermatic.si/" target="_blank"
        >Intermatic d.o.o</b-link
      >
      <span class="d-none d-sm-inline-block"
        >, {{ $t('all-rights-reserved') }}</span
      >
    </span>

    <span class="float-md-right d-none d-md-block"
      >{{ $t('development') }}: Foreach Labs d.o.o
      <feather-icon
        icon="GitBranchIcon"
        size="21"
        class="text-primary stroke-current"
      />
      v{{ version }}
    </span>
  </p>
</template>

<script>
import { BLink } from 'bootstrap-vue';

export default {
  components: {
    BLink,
  },
  computed: {
    version() {
      return process.env.VUE_APP_ENV_VERSION;
    },
  },
};
</script>
